var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "intercom" },
    [
      _c("app-navigation", {
        attrs: {
          title: _vm.$t("intercom.contacts"),
          actionButtonIcon: "fa fa-plus",
          actionButtonDesc: _vm.$t("intercom.add-new-contact")
        },
        on: {
          action: function($event) {
            return _vm.$router.push({ name: "ContactAdd" })
          }
        }
      }),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            data: _vm.contacts.slice(
              (_vm.currentPage - 1) * _vm.pageSize,
              (_vm.currentPage - 1) * _vm.pageSize + _vm.pageSize
            ),
            stripe: "",
            height: "500"
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              color: "#999"
                            },
                            attrs: { span: 8 }
                          },
                          [_vm._v(_vm._s(_vm.$t("intercom.name")))]
                        ),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _vm._v(_vm._s(props.row.name))
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              color: "#999"
                            },
                            attrs: { span: 8 }
                          },
                          [_vm._v(_vm._s(_vm.$t("intercom.number-ip")))]
                        ),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _vm._v(_vm._s(props.row.number))
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              color: "#999"
                            },
                            attrs: { span: 8 }
                          },
                          [_vm._v(_vm._s(_vm.$t("intercom.contact-type")))]
                        ),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _vm._v(_vm._s(props.row.contactType))
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              color: "#999"
                            },
                            attrs: { span: 8 }
                          },
                          [_vm._v(_vm._s(_vm.$t("intercom.favorite")))]
                        ),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _vm._v(_vm._s(props.row.favorite ? "True" : "False"))
                        ])
                      ],
                      1
                    ),
                    props.row.rtspUrl
                      ? _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  color: "#999"
                                },
                                attrs: { span: 8 }
                              },
                              [_vm._v(_vm._s(_vm.$t("intercom.rtsp-url")))]
                            ),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _vm._v(_vm._s(props.row.rtspUrl))
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    props.row.snapshotUrl
                      ? _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  color: "#999"
                                },
                                attrs: { span: 8 }
                              },
                              [_vm._v(_vm._s(_vm.$t("intercom.snapshot-url")))]
                            ),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _vm._v(_vm._s(props.row.snapshotUrl))
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    props.row.contactType != "standart" &&
                    props.row.contactType != "emergency"
                      ? _c(
                          "div",
                          { staticStyle: { "margin-top": "20px" } },
                          [
                            _c(
                              "el-table",
                              { attrs: { data: props.row.actions } },
                              [
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: { label: _vm.$t("intercom.actions") }
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "icon",
                                        label: _vm.$t("intercom.icon"),
                                        width: "80"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _c("div", [
                                                  _c("img", {
                                                    staticClass: "icon-contact",
                                                    attrs: {
                                                      src:
                                                        "/assets/icons/" +
                                                        scope.row.icon +
                                                        ".png"
                                                    },
                                                    on: {
                                                      error: _vm.onImageError
                                                    }
                                                  })
                                                ])
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "name",
                                        label: _vm.$t("intercom.name"),
                                        "min-width": "200"
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "actionType",
                                        label: _vm.$t("intercom.action-type"),
                                        "min-width": "150"
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "command",
                                        label: _vm.$t("intercom.command")
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "shortcut",
                                        label: _vm.$t("intercom.shortcut"),
                                        "min-width": "100"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _c("div", [
                                                  _c("i", {
                                                    staticClass: "fa fa-check",
                                                    style: {
                                                      color: scope.row.shortcut
                                                        ? "yellowgreen"
                                                        : "#eee"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.switchActionShortcut(
                                                          props.row,
                                                          scope.row
                                                        )
                                                      }
                                                    }
                                                  })
                                                ])
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "icon",
              label: _vm.$t("intercom.icon"),
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("img", {
                      staticClass: "icon-contact",
                      attrs: {
                        src: "/assets/icons/" + scope.row.icon + ".png"
                      },
                      on: { error: _vm.onImageError }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: _vm.$t("intercom.name"),
              sortable: "",
              "min-width": "150"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "contactType",
              label: _vm.$t("intercom.contact-type"),
              "min-width": "200"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "favorite",
              label: _vm.$t("intercom.favorite"),
              "min-width": "130"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _c("i", {
                        class: scope.row.favorite
                          ? "fa fa-star"
                          : "far fa-star",
                        style: {
                          color: scope.row.favorite ? "#FFC107" : "#9e9e9e"
                        },
                        on: {
                          click: function($event) {
                            return _vm.switchFavorite(scope.row)
                          }
                        }
                      })
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "shortcut",
              label: _vm.$t("intercom.shortcut"),
              "min-width": "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _c("i", {
                        staticClass: "fa fa-check",
                        style: {
                          color: scope.row.shortcut ? "yellowgreen" : "#eee"
                        },
                        on: {
                          click: function($event) {
                            return _vm.switchContactShortcut(scope.row)
                          }
                        }
                      })
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "actions",
              label: _vm.$t("intercom.intercom-actions"),
              "min-width": "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.actions, function(action, index) {
                    return _c(
                      "el-col",
                      {
                        key: index,
                        staticClass: "action-icon",
                        style: {
                          backgroundColor:
                            action.actionType == "DTMF" ? "#03A9F4" : "#8BC34A"
                        }
                      },
                      [
                        _c(
                          "el-tooltip",
                          { attrs: { content: action.name, placement: "top" } },
                          [
                            _c("img", {
                              staticClass: "icon-action-button",
                              attrs: {
                                src: "/assets/icons/" + action.icon + ".png"
                              },
                              on: {
                                error: _vm.onImageError,
                                click: function($event) {
                                  return _vm.executeCommand(action)
                                }
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "right",
              "min-width": "140",
              label: _vm.$t("intercom.actions")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              name: "ContactEdit",
                              params: { id: scope.row.id }
                            })
                          }
                        }
                      },
                      [_c("i", { staticClass: "far fa-edit" })]
                    ),
                    _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            return _vm.dublicateContact(scope.row)
                          }
                        }
                      },
                      [_c("i", { staticClass: "far fa-copy" })]
                    ),
                    _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            return _vm.removeContact(scope.row)
                          }
                        }
                      },
                      [_c("i", { staticClass: "far fa-trash-alt" })]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          "page-sizes": [10, 50, 100, 200, 500],
          "current-page": _vm.currentPage,
          background: "",
          layout: "prev, pager, next, sizes",
          total: _vm.contacts.length,
          "page-size": _vm.pageSize
        },
        on: {
          "update:currentPage": function($event) {
            _vm.currentPage = $event
          },
          "update:current-page": function($event) {
            _vm.currentPage = $event
          },
          "size-change": function($event) {
            _vm.pageSize = $event
          }
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: { title: "New Contact", visible: _vm.dublicatePopupVisible },
          on: {
            "update:visible": function($event) {
              _vm.dublicatePopupVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "dublicateForm", attrs: { model: _vm.dublicatedContact } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Name",
                    "label-width": "100",
                    prop: "name",
                    rules: [
                      {
                        required: true,
                        message: _vm.$t("intercom.form-rules.type-name")
                      }
                    ]
                  }
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.dublicatedContact.name,
                      callback: function($$v) {
                        _vm.$set(_vm.dublicatedContact, "name", $$v)
                      },
                      expression: "dublicatedContact.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Number/IP Address",
                    "label-width": "100",
                    prop: "number",
                    rules: [
                      {
                        required: true,
                        message: _vm.$t("intercom.form-rules.type-number-ip")
                      }
                    ]
                  }
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.dublicatedContact.number,
                      callback: function($$v) {
                        _vm.$set(_vm.dublicatedContact, "number", $$v)
                      },
                      expression: "dublicatedContact.number"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Count", "label-width": "100" } },
                [
                  _c("el-input", {
                    attrs: { type: "number", min: "1" },
                    model: {
                      value: _vm.dublicateCount,
                      callback: function($$v) {
                        _vm.dublicateCount = $$v
                      },
                      expression: "dublicateCount"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dublicatePopupVisible = false
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmDublicate }
                },
                [_vm._v("Confirm")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }