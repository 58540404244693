<template>
  <div class="intercom">
    <app-navigation :title="$t('intercom.contacts')" actionButtonIcon="fa fa-plus" :actionButtonDesc="$t('intercom.add-new-contact')" @action="$router.push({name: 'ContactAdd'})"></app-navigation>

    <el-table :data="contacts.slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize)" stripe height="500" v-loading="loading">

      <el-table-column type="expand">
        <template slot-scope="props">

          <el-row>
            <el-col :span="8" style="font-weight: bold; color: #999">{{ $t('intercom.name') }}</el-col>
            <el-col :span="12">{{ props.row.name }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="8" style="font-weight: bold; color: #999">{{ $t('intercom.number-ip') }}</el-col>
            <el-col :span="12">{{ props.row.number }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="8" style="font-weight: bold; color: #999">{{ $t('intercom.contact-type') }}</el-col>
            <el-col :span="12">{{ props.row.contactType }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="8" style="font-weight: bold; color: #999">{{ $t('intercom.favorite') }}</el-col>
            <el-col :span="12">{{ props.row.favorite ? 'True' : 'False' }}</el-col>
          </el-row>
          <el-row v-if="props.row.rtspUrl">
            <el-col :span="8" style="font-weight: bold; color: #999">{{ $t('intercom.rtsp-url') }}</el-col>
            <el-col :span="12">{{ props.row.rtspUrl }}</el-col>
          </el-row>
          <el-row v-if="props.row.snapshotUrl">
            <el-col :span="8" style="font-weight: bold; color: #999">{{ $t('intercom.snapshot-url') }}</el-col>
            <el-col :span="12">{{ props.row.snapshotUrl }}</el-col>
          </el-row>

          <div v-if="props.row.contactType != 'standart' && props.row.contactType != 'emergency'" style="margin-top: 20px">

            <el-table :data="props.row.actions">
              <el-table-column :label="$t('intercom.actions')">
                <el-table-column prop="icon" :label="$t('intercom.icon')" width="80">
                  <template slot-scope="scope">
                    <!-- <div style="width: 100%; text-align: center"> -->
                      <div>
                      <img @error="onImageError" :src="'/assets/icons/' + scope.row.icon + '.png'" class="icon-contact">
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="name" :label="$t('intercom.name')" min-width="200">
                </el-table-column>
                <el-table-column prop="actionType" :label="$t('intercom.action-type')" min-width="150">
                </el-table-column>
                <el-table-column prop="command" :label="$t('intercom.command')">
                </el-table-column>
                <el-table-column prop="shortcut" :label="$t('intercom.shortcut')" min-width="100">
                  <template slot-scope="scope">
                    <div><i class="fa fa-check" :style="{color: scope.row.shortcut ? 'yellowgreen' : '#eee'}" @click="switchActionShortcut(props.row, scope.row)"></i></div>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>


        </template>
      </el-table-column>

      <el-table-column
              prop="icon"
              :label="$t('intercom.icon')"
              width="100">

        <template slot-scope="scope">
          <img @error="onImageError" :src="'/assets/icons/' + scope.row.icon + '.png'" class="icon-contact">
        </template>

      </el-table-column>

      <el-table-column
              prop="name"
              :label="$t('intercom.name')"
              sortable
              min-width="150">
      </el-table-column>

      <el-table-column
              prop="contactType"
              :label="$t('intercom.contact-type')"
              min-width="200">
      </el-table-column>

      <el-table-column
              prop="favorite"
              :label="$t('intercom.favorite')"
              min-width="130">
        <template slot-scope="scope">
          <div>
            <i :class="scope.row.favorite ? 'fa fa-star' : 'far fa-star'"  :style="{color: scope.row.favorite ? '#FFC107' : '#9e9e9e'}" @click="switchFavorite(scope.row)"></i>
          </div>
        </template>
      </el-table-column>

      <el-table-column
              prop="shortcut"
              :label="$t('intercom.shortcut')"
              min-width="200">
        <template slot-scope="scope">
          <!-- <div style="width: 100%; text-align: center"> -->
            <div>

            <i class="fa fa-check" :style="{color: scope.row.shortcut ? 'yellowgreen' : '#eee'}" @click="switchContactShortcut(scope.row)"></i>

            <!--<i :class="scope.row.shortcut ? 'fa fa-star' : 'fa fa-star-o'"  :style="{color: scope.row.favorite ? '#FFC107' : '#9e9e9e'}" @click="switchFavorite(scope.row)"></i>-->
          </div>
        </template>
      </el-table-column>

      <el-table-column
              prop="actions"
              :label="$t('intercom.intercom-actions')"
              min-width="200">

        <template slot-scope="scope">
          <el-col v-for="(action, index) in scope.row.actions" :key="index" class="action-icon" :style="{backgroundColor: action.actionType == 'DTMF' ? '#03A9F4' : '#8BC34A'}">
            <el-tooltip :content="action.name" placement="top">
              <img @error="onImageError" :src="'/assets/icons/' + action.icon + '.png'" class="icon-action-button" @click="executeCommand(action)">
            </el-tooltip>

          </el-col>
        </template>

      </el-table-column>

      <el-table-column
              align="right"
              min-width="140"
              :label="$t('intercom.actions')">

        <template slot-scope="scope">
          <span @click="$router.push({name: 'ContactEdit', params: {id: scope.row.id}})"><i class="far fa-edit"></i></span>
          <span @click="dublicateContact(scope.row)"><i class="far fa-copy"></i></span>
          <span @click="removeContact(scope.row)"><i class="far fa-trash-alt"></i></span>
        </template>

      </el-table-column>

    </el-table>


    <el-pagination style="text-align: center; margin-top: 10px"
                   :page-sizes="[10, 50, 100, 200, 500]"
                   :current-page.sync="currentPage" background
                   layout="prev, pager, next, sizes"
                   :total="contacts.length"
                   :page-size="pageSize" @size-change="pageSize = $event">

    </el-pagination>


    <el-dialog title="New Contact" :visible.sync="dublicatePopupVisible">
      <el-form ref="dublicateForm" :model="dublicatedContact">
        <el-form-item label="Name" label-width="100" prop="name" :rules="[{required: true, message: $t('intercom.form-rules.type-name')}]">
          <el-input v-model="dublicatedContact.name"></el-input>
        </el-form-item>
        <el-form-item label="Number/IP Address" label-width="100" prop="number" :rules="[{required: true, message: $t('intercom.form-rules.type-number-ip')}]">
          <el-input v-model="dublicatedContact.number"></el-input>
        </el-form-item>
        <el-form-item label="Count" label-width="100">
          <el-input type="number" v-model="dublicateCount" min="1"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dublicatePopupVisible = false">Cancel</el-button>
        <el-button type="primary" @click="confirmDublicate">Confirm</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import Navigation from '@/components/Navigation'
  import {METHOD_GET_CONTACTS, METHOD_REMOVE_CONTACT, METHOD_EDIT_CONTACT} from '@/store/method-names';
  import {METHOD_ADD_CONTACT} from '@/store/method-names';
  import { initializeWebSocket } from "@/utils/socket-utils"
  import {mapGetters} from "vuex"

  export default {
    name: "Contacts",
    components: {
      appNavigation: Navigation
    },

    data: function () {
      return {
        loading: true,
        currentPage: 1,
        pageSize: 10,
        dublicatedContact: {},
        dublicatePopupVisible: false,
        dublicateCount: 1,
      }
    },

    computed: {
      ...mapGetters([
        "contacts"
      ]),
      isConnected() {
      return this.$store.getters.isConnected;
    },
    },

    watch: {
    isConnected(value) {
      if (value) {
        this.fetchData();
      }
    }
  },

    methods: {
      onImageError: function(e){
        e.target.src = "/assets/person.png";
      },

      dublicateContact(contact){
        this.dublicatedContact = {...contact}
        this.dublicatePopupVisible = true
        console.log("dublicateContact", contact)
      },

      async confirmDublicate(){
        this.$refs.dublicateForm.validate(async valid => {
          if (valid && this.dublicateCount > 0) {
            this.dublicatePopupVisible = false

            this.dublicatedContact.sipAddress = "sip:100@" + this.dublicatedContact.number
            delete this.dublicatedContact.id

            console.log("confirmDublicate", this.dublicatedContact)

            for (var i = 0; i < this.dublicateCount; i++) {
              await this.$store.dispatch(METHOD_ADD_CONTACT, this.dublicatedContact)
            }

            this.$message({ message: this.$t("intercom.new-contacts-added"), type: "success" })
          }
        })
      },

      removeContact: function (contact) {
        this.$confirm(this.$t('alert.message.confirm-delete'), this.$t('alert.title.warning'), {
          confirmButtonText: this.$t('alert.button.ok'),
          cancelButtonText: this.$t('alert.button.cancel'),
          type: 'warning'
        }).then(() => {
          this.$store.dispatch(METHOD_REMOVE_CONTACT, contact.id).then(response => {
            this.$message({
              message: this.$t(response.message),
              type: 'success'
            })
          }).catch(reason => {
            this.$message({
              message: this.$t(reason),
              type: 'error'
            })
          })
        })
      },

      switchFavorite(contact){
        contact.favorite = !contact.favorite
        this.$store.dispatch(METHOD_EDIT_CONTACT, contact)
      },

      switchContactShortcut(contact){
        contact.shortcut = !contact.shortcut
        this.$store.dispatch(METHOD_EDIT_CONTACT, contact)
      },

      switchActionShortcut: function(contact, action){
        action.shortcut = !action.shortcut
        this.$store.dispatch(METHOD_EDIT_CONTACT, contact)
      },

      executeCommand: function (action) {
        this.$store.dispatch("executeSipAction", action).then(response => {
          this.$message({ message: this.$t(response.message), type: "success" })
        }).catch(reason => {
          this.$message({ message: this.$t(reason), type: "error" })
        })

        // this.$message({
        //   message: this.$t('intercom.command-executed', [action.name]),
        //   type: "success"
        // })
      },
      async fetchData() {
      try {
        await this.$store.dispatch("getContacts");
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    }
    },

    created(){
      if (!this.isConnected) {
      initializeWebSocket()
    }
    this.fetchData()
  }
}
</script>

<style scoped>
  @import "../../style/intercom.css";
  span i {
    font-size: large;
    padding: 10px;
    cursor: pointer;
  }
  div i {
    font-size: large;
    cursor: pointer;
  }

  span i:hover {
    background-color: #00BFD7;
    border-radius: 5px;
    color: white;
  }

  span i:active {
    box-shadow: 0px 0px 10px #00BFD7;
    border-radius: 5px;
  }

  .action-icon {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: green;
    margin: 3px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .action-icon:hover {
    box-shadow: 0px 0px 5px #333;
  }

  .icon-contact {
    filter: brightness(0.45);
    width: 40px;
    height: 40px;
  }


  .icon-action-button {
    width: 24px;
    height: 24px;
  }

</style>